import React, { useState, Fragment } from 'react';
import styled from 'styled-components';
import Img from 'gatsby-image';
import _map from 'lodash/map';

import { Container, Inner } from './Elements';
import { diagramTypes } from '../types/propTypes';
import { colors, font } from '../consts/style';
import mq from '../style/mq';

import Plus from '../images/plus-icon-green.svg';
import Video from './Video';

const DiagramImage = styled(Img)`
  width: 100%;
  ${mq.tabletSmall`
  display: none;
  `}
`;

const MobileDiagramImage = styled(Img)`
  display: none;
  ${mq.tabletSmall`
  display: block;
  `}
`;

const Point = styled.div`
  display: none;
  ${mq.tabletSmall`
    display: none !important;
  `}
  img {
    width: 4rem;
  }
  .blurb {
    background: ${colors.green};
    color: white;
    padding: 2.4rem;
    width: 30vw;
    border-radius: 0 4.2rem 4.2rem 4.2rem;
    position: absolute;
    bottom: 0;
    right: 0;
    transform: translateY(92%) translateX(96%);
    border: 1px solid #707070;
    transition: all 0.3s ease;
    opacity: 1;
    pointer-events: all;
    &.hidden {
      opacity: 0;
      bottom: 4rem;
      right: 4rem;
      pointer-events: none;
    }
    p {
      margin: 0;
      ${font.body};
      &.title {
        font-weight: bold;
        padding-bottom: 1.2rem;
        text-transform: uppercase;
      }
    }
  }
  z-index: 10;
  &.point-0 {
    display: block;
    position: absolute;
    top: 4.5%;
    left: 50%;
    z-index: 20;
  }
  &.point-1 {
    display: block;
    position: absolute;
    top: 25%;
    left: 28%;
    z-index: 25;
  }
  &.point-2 {
    display: block;
    position: absolute;
    top: 25%;
    left: 54%;
    z-index: 20;
  }
  &.point-3 {
    display: block;
    position: absolute;
    top: 30%;
    right: 20%;
    z-index: 16;

    .blurb {
      transform: translateY(92%) translateX(-5%);
      border-radius: 4.2rem 0rem 4.2rem 4.2rem;
    }
  }
  &.point-4 {
    display: block;
    position: absolute;
    top: 50%;
    right: 42%;
    z-index: 20;
  }
  &.point-5 {
    display: block;
    position: absolute;
    top: 60%;
    left: 36%;
    z-index: 15;
  }
  &.point-6 {
    display: block;
    position: absolute;
    z-index: 25;
    top: 50%;
    left: 18%;
  }
  &.point-7 {
    display: block;
    position: absolute;
    top: 64%;
    right: 30%;
    .blurb {
      transform: translateY(92%) translateX(-5%);
      border-radius: 4.2rem 0rem 4.2rem 4.2rem;
    }
  }
  &.point-8 {
    display: block;
    position: absolute;
    bottom: 10%;
    right: 10%;
    .blurb {
      transform: translateY(92%) translateX(-5%);
      border-radius: 4.2rem 0rem 4.2rem 4.2rem;
    }
  }
  &.point-9 {
    display: block;
    position: absolute;
    bottom: 10%;
    right: 50%;
    .blurb {
      transform: translateY(92%) translateX(-5%);
      border-radius: 4.2rem 0rem 4.2rem 4.2rem;
    }
  }
  &.point-10 {
    display: block;
    position: absolute;
    top: 117rem;
    left: 44rem;
  }
`;

const TabletDown = styled.div`
  display: none;
  ${mq.tablet`
    display: flex;
  `}
`;

const TopWrapper = styled.div`
  position: relative;
  background: ${colors.blue};
  padding: 6rem 0;
  &:after {
    content: '';
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    width: 0;
    height: 0;
    z-index: 1;
    border-left: 4rem solid transparent;
    border-right: 4rem solid transparent;
    border-top: 4rem solid ${colors.green};
  }

  ${Inner} {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .video {
      ${mq.tablet`
        display: none;
      `}
    }

    .video,
    .text {
      width: 49%;
      color: white;

      ${mq.tablet`
        width: 100%;
      `}

      .icon {
        margin-bottom: 2rem;
      }

      .mt1 {
        h2 {
          ${font.h1};
          line-height: 150%;
          color: white;
          margin-bottom: 2rem;
        }
      }

      img {
        max-width: 100%;
      }

      .plyr {
        position: relative;
        top: 10%;
      }
    }
  }
`;

const DiagramWrapper = styled.div`
  padding-top: 8rem;
  ${Inner} {
    position: relative;
    margin-bottom: 4rem;
  }
  .mobile-button {
    width: 100%;
    text-align: center;
    button {
      ${font.button};
      background: ${colors.green};
    }
  }
`;

const ContentWrapper = styled.div`
  padding: 4rem 0;
  ${Inner} {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;

    .other {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
    }

    .text {
      width: 52rem;
      max-width: 100%;
    }

    .buttons {
      flex-shrink: 0;
      button {
        ${font.button};
        background: ${colors.green};
        margin-right: 2rem;
        margin-bottom: 1rem;
      }
    }

    .available {
      width: 34rem;
      max-width: 100%;
      display: flex;
      align-items: center;
      a {
        margin-left: 2rem;
      }
      img {
        max-height: 7.2rem;
      }
    }
  }
`;

export default function CentDiagram({
  diagramIcon,
  diagramImage,
  diagramImageMobile,
  diagramInfoHeading1,
  diagramInfoHeading2,
  diagramInfoBody1,
  diagramInfoBody2,
  diagramPoints,
  salesSheet,
  diagramVideoId,
  vendors,
}) {
  const [indexShown, setShown] = useState(new Array());

  function renderPoints() {
    return _map(diagramPoints, (dp, i) => {
      return (
        <Point
          onMouseEnter={() => handlePointAdd(i)}
          key={dp.id}
          className={`point-${i}`}
        >
          <img src={Plus} alt="Plus icon" />
          <div className={`blurb ${checkIndex(i)}`}>
            <p className="title">{dp.title}</p>
            <p className="body">{dp.body}</p>
          </div>
        </Point>
      );
    });
  }

  function handlePointAdd(i) {
    setShown([i]);
  }

  function checkIndex(i) {
    if (indexShown.includes(i)) return '';
    return 'hidden';
  }

  return (
    <Fragment>
      <TopWrapper>
        <Container>
          <Inner>
            <div className="video">
              <div className="icon">
                <img src={diagramIcon} alt="Icon" />
              </div>
              <div className="video-wrap">
                <Video
                  videoId={diagramVideoId}
                  path="/centrifuge"
                  id="cent-video"
                />
              </div>
            </div>
            <div className="text">
              <div className="title">
                <div className="mt1">
                  <h2>{diagramInfoHeading1}</h2>
                </div>
              </div>
              <div
                className="mb1"
                dangerouslySetInnerHTML={{ __html: diagramInfoBody1 }}
              ></div>
            </div>
          </Inner>
        </Container>
      </TopWrapper>

      <DiagramWrapper>
        <Container>
          <Inner onMouseLeave={() => handlePointAdd('')}>
            <DiagramImage fluid={diagramImage} />
            <MobileDiagramImage fluid={diagramImageMobile} />
            <TabletDown>
              <div className="mobile-button">
                <a target="blank" href={salesSheet.url}>
                  <button>
                    <span>Download Sales Sheet</span>
                  </button>
                </a>
              </div>
            </TabletDown>
            {renderPoints()}
          </Inner>
          <TabletDown>
            <Inner>
              <Video
                videoId={diagramVideoId}
                path="/centrifuge"
                id="cent-video-small"
              />
            </Inner>
          </TabletDown>
        </Container>
      </DiagramWrapper>

      <ContentWrapper>
        <Container>
          <Inner>
            <div className="text">
              <div className="title2">
                <h2>{diagramInfoHeading2}</h2>
              </div>
              <div
                className="body2"
                dangerouslySetInnerHTML={{ __html: diagramInfoBody2 }}
              ></div>
            </div>
            <div className="buttons">
              <a target="blank" href={salesSheet.url}>
                <button>
                  <span>Download Sales Sheet</span>
                </button>
              </a>
            </div>
            <div className="available">
              <div className="text">
                AVAILABLE
                <br /> THROUGH:
              </div>
              {vendors.map(v => (
                <a target="blank" key={v.id} href={v.link}>
                  <img src={v.image.url} alt="Available through" />
                </a>
              ))}
            </div>
          </Inner>
        </Container>
      </ContentWrapper>
    </Fragment>
  );
}

CentDiagram.propTypes = diagramTypes;
