import React, { Fragment } from 'react';
import { graphql, useStaticQuery } from 'gatsby';

import SEO from '../components/SEO';
import Hero from '../components/Hero';
import PlugIntro from '../components/PlugIntro';
import InfoBlocks from '../components/InfoBlocks';
import PlugDiagram from '../components/PlugDiagram';
import useWindow from '../hooks/useWindow';
import useBrowser from '../hooks/useBrowser';
import CentDiagram from '../components/CentDiagram';

const centQuery = graphql`
  {
    datoCmsCentrifugePage {
      seoMetaTags {
        ...GatsbyDatoCmsSeoMetaTags
      }
      heroBackground {
        fluid(maxWidth: 2000, imgixParams: { fm: "jpg", auto: "compress" }) {
          ...GatsbyDatoCmsFluid_noBase64
        }
      }
      heroMobileBackground {
        fluid(maxWidth: 1200, imgixParams: { fm: "jpg", auto: "compress" }) {
          ...GatsbyDatoCmsFluid_noBase64
        }
      }
      heroTextImage {
        url
      }
      introLogo {
        url
      }
      introTitle
      introBody
      introMp4 {
        url
      }
      introWebm {
        url
      }
      introImage {
        url
        fluid(maxWidth: 1200, imgixParams: { fm: "png", auto: "compress" }) {
          ...GatsbyDatoCmsFluid_noBase64
        }
      }
      infoBlocks {
        id
        icon {
          url
        }
        title
        body
      }
      diagramIcon {
        url
      }
      diagramImage {
        fluid(maxWidth: 1200, imgixParams: { fm: "jpg", auto: "compress" }) {
          ...GatsbyDatoCmsFluid_noBase64
        }
      }
      diagramImageMobile {
        fluid(maxWidth: 800, imgixParams: { fm: "jpg", auto: "compress" }) {
          ...GatsbyDatoCmsFluid_noBase64
        }
      }
      diagramVideoId
      diagramInfoHeading1
      diagramInfoHeading2
      diagramInfoBody1
      diagramInfoBody2
      diagramPoints {
        id
        title
        body
      }
      salesSheet {
        url
      }
      vendors {
        id
        link
        image {
          url
        }
      }
    }
  }
`;

export default function Centrifuge() {
  const data = useStaticQuery(centQuery);
  const { isSmall } = useWindow();
  const browser = useBrowser();

  const {
    seoMetaTags,
    heroBackground,
    heroMobileBackground,
    heroTextImage,
    introLogo,
    introTitle,
    introBody,
    introMp4,
    introWebm,
    introImage,
    infoBlocks,
    diagramVideoId,
    diagramInfoHeading1,
    diagramInfoBody1,
    diagramInfoHeading2,
    diagramInfoBody2,
    diagramIcon,
    diagramImage,
    diagramImageMobile,
    diagramPoints,
    salesSheet,
    vendors,
  } = data.datoCmsCentrifugePage;

  const heroProps = {
    background: heroBackground.fluid,
    backgroundMobile: heroMobileBackground.fluid,
    textImage: heroTextImage.url,
    isMobile: isSmall,
    pathname: '/centrifuge',
  };

  const introProps = {
    logo: introLogo.url,
    title: introTitle,
    body: introBody,
    introMp4,
    introWebm,
    introImage,
    isSmall: isSmall || browser.mobile,
  };

  const infoBlockProps = {
    blocks: infoBlocks,
    path: '/centrifuge',
  };

  const diagramProps = {
    diagramIcon: diagramIcon.url,
    diagramImage: diagramImage.fluid,
    diagramImageMobile: diagramImageMobile.fluid,
    diagramInfoHeading1,
    diagramInfoBody1,
    diagramInfoHeading2,
    diagramInfoBody2,
    diagramPoints,
    salesSheet,
    diagramVideoId,
    vendors,
  };

  return (
    <Fragment>
      <SEO meta={seoMetaTags} />
      <Hero {...heroProps} />
      <div id="intro" />
      <PlugIntro {...introProps} />
      <InfoBlocks {...infoBlockProps} />
      <CentDiagram {...diagramProps} />
    </Fragment>
  );
}
